// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    /*&::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }*/
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';

p {
    font-family: $font-family-2;
}




/*****************************************************/
/* ACCUEIL
/*****************************************************/

#blocIntro {
    padding: 100px 5% 0 5%;
    margin-bottom: -200px;
    position: relative;


    h2 {
        font-weight: 700;
        font-size: $font-size-50;
        padding-bottom: 35px;
    }
    p {
        font-size: $font-size-36;
        font-weight: normal;
        line-height: 1.5;
    }
}

#bloc50-50 {
    padding: 50px 5%;
    display: flex;
    justify-content: space-between;

    h3 {
        font-weight: 700;
        font-size: $font-size-50;
        padding-bottom: 35px;
        color: #000;
        text-transform: initial;
    }
    p {
        font-size: $font-size-36;
        font-weight: normal;
        line-height: 1.5;
    }
    li {
        text-transform: initial;
        color: #000;
        font-size: $font-size-36;
        font-weight: normal;
        list-style: none;
        margin-bottom: 20px;
        padding-left: 45px;
        line-height: 1.25;
    }
    li.un {
        background: url(../images/accueil_section04_icon_puce01.jpg) no-repeat left;
    }
    li.deux {
        background: url(../images/accueil_section04_icon_puce02.jpg) no-repeat left;
    }
    li.trois {
        background: url(../images/accueil_section04_icon_puce03.jpg) no-repeat left;
    }
    li.quatre {
        background: url(../images/accueil_section04_icon_puce04.jpg) no-repeat left;
    }
    li.cinq {
        background: url(../images/accueil_section04_icon_puce05.jpg) no-repeat left;
    }
    li.six {
        background: url(../images/accueil_section04_icon_puce06.jpg) no-repeat left;
    }

    marquee {
        width: 525px;
        height: 300px;
    }

    .left-side {
        background: url("../images/accueil_section04_shadow.jpg") no-repeat;
        background-size: contain;
        padding: 30px;
    }
    .blocLogo {
        display: flex;
        align-items: center;
        padding-top: 50px;
        .logoSBQ {
            padding-right: 100px;
        }
    }
}

#blocBurst {
    background: url(../images/burst_section_BG.jpg) no-repeat;
    padding: 75px 2%;
    text-align: center;

    .burst {
        background: url(../images/accueil_section05_burst.png) no-repeat center;
        padding: 200px 0;
        width: 50%;
        margin: auto;
        &:hover {
            background: url(../images/accueil_section05_burst_hover.png) no-repeat center;
        }
    }
    h3 {
        font-weight: 700;
        font-size: $font-size-50;
        color: #fff;
        text-transform: initial;
    }
    h3.titre {
        padding-top: 145px;
    }
}


/*****************************************************/
/* BINGO BROSSARD
/*****************************************************/

.blocBingo {
    margin-top: -785px;
    z-index: 1;

    #bloc50-50_bingo {
        display: flex;

        h3 {
            font-weight: 700;
            font-size: $font-size-50;
            padding-bottom: 35px;
            color: #000;
            text-transform: initial;
        }
        p {
            font-size: $font-size-36;
            font-weight: normal;
            line-height: 1.5;
        }
        .left-side {
            width: 45%;
        }
        .right-side {
            width: 55%;

            .intro {
                padding-top: 175px;
                h2 {
                    font-weight: 700;
                    font-size: $font-size-50;
                    padding-bottom: 100px;
                    color: #000;
                }
                h3 {
                    padding-bottom: 15px;
                }
            }
            .description {
                padding: 150px 0 0 4%;
            }
            li {
                text-transform: initial;
                color: #000;
                font-size: $font-size-36;
                font-weight: normal;
                list-style: none;
                margin-bottom: 20px;
                padding-left: 45px;
                line-height: 1.25;
            }
            li.un {
                background: url(../images/accueil_section04_icon_puce01.jpg) no-repeat left;
            }
            li.deux {
                background: url(../images/accueil_section04_icon_puce02.jpg) no-repeat left;
            }
            li.trois {
                background: url(../images/accueil_section04_icon_puce03.jpg) no-repeat left;
            }
            li.quatre {
                background: url(../images/accueil_section04_icon_puce04.jpg) no-repeat left;
            }
            li.cinq {
                background: url(../images/accueil_section04_icon_puce05.jpg) no-repeat left;
            }
            li.six {
                background: url(../images/accueil_section04_icon_puce06.jpg) no-repeat left;
            }

            .blocLogo {
                display: flex;
                align-items: center;
                padding: 100px 5%;
                .logoSBQ {
                    padding-right: 100px;
                }
            }

            .btnCalendrier {
                background: #861cb2;
                padding: 15px;
                box-shadow: 10px 4px 10px 0px black;
                &:hover {
                    background: #ff1f1f;
                }
                h4 {
                    font-weight: 700;
                    font-size: $font-size-50;
                    color: #fff;
                }
                p {
                    font-weight: normal;
                    font-size: $font-size-36;
                    color: #fff;
                    span {
                        font-weight: bold;
                        padding-left: 35px;
                    }
                }
                .border {
                    border: 1px solid #fff;
                    padding: 58px;
                }
            }
        }
    }

    #bloc50-50_horaires {
        padding: 100px 0 50px 2%;

        h3 {
            font-weight: 700;
            font-size: $font-size-50;
            color: #000;
            text-transform: initial;
        }

        .contenu {
            display: flex;

            .left-side {
                width: 45%;

                .horaires {
                    display: flex;
                    justify-content: center;
                    & > div {
                      width: 30%;
                    }

                }
                .border-shadow {
                    margin-left: -50px;
                }
                .jourHoraires {
                    display: flex;
                    justify-content: center;
                    padding-top: 35px;
                    & > div {
                      width: 30%;
                      display: flex;
                    }
                    h4 {
                        font-weight: 700;
                        font-size: $font-size-42;
                        border-bottom: 1px solid #000;
                    }
                }
            }
            .right-side {
                width: 55%;

                .images {
                    display: flex;
                    padding-bottom: 100px;
                    padding-top: 35px;
                    .img.first {
                        margin-right: 10px;
                    }
                }

                .btnSession {
                    background: #fb710f;
                    padding: 15px;
                    box-shadow: 10px 4px 10px 0px black;
                    &:hover {
                        background: #ff1f1f;
                    }
                    h4 {
                        font-weight: 700;
                        font-size: $font-size-50;
                        color: #fff;
                        text-align: center;
                    }
                    p {
                        font-weight: normal;
                        font-size: $font-size-36;
                        color: #fff;
                        text-align: center;
                        line-height: 1.5;
                        margin: 0;
                    }
                    p.ici {
                        font-weight: 700;
                    }
                    .border {
                        border: 1px solid #fff;
                        padding: 58px;
                    }
                }

                .texte {
                    padding-top: 35px;
                    p {
                        font-weight: normal;
                        font-size: $font-size-36;
                        text-align: center;
                        margin: 0;
                        line-height: 1.3;
                    }
                }
            }
        }
    }
}


/*****************************************************/
/* COORDONNÉES
/*****************************************************/

.blocCoordonnees {
    margin-top: -785px;
    z-index: 1;

    #bloc50-50_coordonnees {
        padding: 100px 0 0 0;
        display: flex;

        h3 {
            font-weight: 700;
            font-size: $font-size-50;
            color: #000;
            text-transform: initial;
        }

            .left-side {
                width: 30.5%;
                margin-right: 10%;

                .blocMap {
                    padding-top: 215px;
                }
            }
            .right-side {
                width: 55%;

                .intro {
                    padding-top: 75px;
                    h2 {
                        font-weight: 700;
                        font-size: $font-size-50;
                        padding-bottom: 100px;
                        color: #000;
                    }
                    h3 {
                        font-weight: 500;
                        font-size: $font-size-48;
                        color: #000;
                        text-transform: initial;
                    }
                    h4 {
                        font-weight: 500;
                        font-size: $font-size-40;
                        color: #000;
                    }
                }

                .horaire {
                    padding-top: 45px;
                    background: url("../images/accueil_section04_shadow.jpg") no-repeat;
                    margin-top: 125px;

                    .jourHoraires {
                        display: flex;
                        padding-top: 35px;
                        & > div {
                          width: 30%;
                          display: flex;
                        }
                        h4 {
                            font-weight: 700;
                            font-size: $font-size-42;
                            border-bottom: 1px solid #000;
                        }
                    }
                    .horaires {
                        display: flex;
                        & > div {
                          width: 30%;
                          padding-right: 15px;
                        }
                    }
                }
            }
        }
}

#blocFormulaire {
    background: #fb710f;
    padding: 50px 2% 25px 2%;

    table.xdebug-error.xe-notice {
        display: none;
    }
    h3 {
        font-weight: 700;
        font-size: $font-size-50;
        padding-bottom: 30px;
        color: #000;
        text-transform: initial;
    }
    form input.form-control, form textarea.form-control {
        height: 35px;
        width: 50%;
        display: initial;
    }
    form label {
        color: #000;
        font-weight: normal;
        font-size: $font-size-30;
        width: 25%;
    }
    .form-group {
        margin-bottom: -10px;
    }
    button#submit {
        background: #fff;
        color: #000;
        border: none;
        font-weight: bold;
        font-size: $font-size-22;
    }
    form .buttons {
        margin-bottom: 0;
    }
}
a.btn.btn-send {
    display: none;
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1860px) {

nav.module-menu .menu-desktop .wrapper.right .main-menu > ul .blocMenuTel {
    margin-left: 380px;
}
.blocBingo {
    margin-top: -740px;
}
}

@media screen and (max-width: 1795px) {

.blocBingo #bloc50-50_bingo p br {
    display: none;
}
}

@media screen and (max-width: 1750px) {

nav.module-menu .menu-desktop .wrapper.right .main-menu > ul .blocMenuTel {
    margin-left: 320px;
}
.blocCoordonnees #bloc50-50_coordonnees .right-side .intro {
    padding-top: 110px;
}
.blocCoordonnees #bloc50-50_coordonnees .left-side .blocMap {
    padding-top: 250px;
}
}

@media screen and (max-width: 1725px) {

.blocBingo {
    margin-top: -710px;
}
}

@media screen and (max-width: 1670px) {

nav.module-menu .menu-desktop .wrapper.right .main-menu > ul .blocMenuTel {
    margin-left: 250px;
}
#blocIntro {
    margin-bottom: 0px;
}
.blocBingo {
    margin-top: -700px;
}
}

@media screen and (max-width: 1630px) {

.blocBingo {
    margin-top: -665px;
}
}

@media screen and (max-width: 1600px) {

.blocCoordonnees #bloc50-50_coordonnees .right-side .intro {
    padding-top: 150px;
}
.blocCoordonnees #bloc50-50_coordonnees .left-side .blocMap {
    padding-top: 59%;
}
}

@media screen and (max-width: 1570px) {

nav.module-menu .menu-desktop .wrapper.right .main-menu > ul .blocMenuTel {
    margin-left: 185px;
}
#blocBurst .burst {
    width: 60%;
}
.blocBingo {
    margin-top: -645px;
}
#bloc50-50 .right-side {
    width: 50%;
}
.blocBingo #bloc50-50_bingo {
    padding: 0 2%;
}
.blocBingo #bloc50-50_bingo h3 {
    font-size: 2.4rem;
}
}

@media screen and (max-width: 1500px) {

nav.module-menu .menu-desktop .wrapper.right .main-menu > ul .blocMenuTel {
    margin-left: 130px;
}
.blocCoordonnees #bloc50-50_coordonnees .right-side .intro {
    padding-top: 21%;
}
.blocCoordonnees #bloc50-50_coordonnees .left-side .blocMap {
    padding-top: 67%;
}
.blocBingo #bloc50-50_bingo .right-side .intro {
    padding-top: 135px;
}
.blocBingo {
    margin-top: -585px;
}
.blocBingo #bloc50-50_bingo h3 {
    font-size: 2.4rem;
}
.blocBingo #bloc50-50_bingo .right-side li {
    font-size: 1.7rem;
}
.blocBingo #bloc50-50_bingo .right-side .btnCalendrier {
    display: none;
}
.blocBingo #bloc50-50_horaires .contenu .right-side .texte {
    padding-right: 15px;
}
.blocBingo #bloc50-50_horaires .contenu .right-side .texte p br {
    display: none;
}
.blocBingo #bloc50-50_bingo .right-side .intro {
    padding-top: 65px;
}
}

@media screen and (max-width: 1445px) {

nav.module-menu .menu-desktop .wrapper.left .logo {
    width: 80%;
}
nav.module-menu .menu-desktop .wrapper.right .main-menu > ul .blocMenuTel {
    margin-left: 0px;
}
nav.module-menu .menu-desktop {
    height: 125px;
}
.blocBingo #bloc50-50_bingo .right-side .intro h2 {
    font-size: 2.4rem;
}
.blocBingo #bloc50-50_bingo p {
    font-size: 1.8rem;
}
.blocBingo #bloc50-50_bingo h3 {
    font-size: 2.2rem;
}
}

@media screen and (max-width: 1400px) {

.blocCoordonnees #bloc50-50_coordonnees .right-side .intro {
    padding-top: 29%;
}
.blocCoordonnees #bloc50-50_coordonnees .left-side .blocMap {
    padding-top: 79%;
}
}

@media screen and (max-width: 1355px) {

.blocBingo {
    margin-top: -540px;
}
}

@media screen and (max-width: 1315px) {

nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li.item div {
    font-size: 1.2rem;
}
#blocBurst .burst {
    width: 80%;
}

}

@media screen and (max-width: 1300px) {

nav.module-menu .menu-desktop .wrapper.left .logo {
    width: 100%;
    max-width: 330px;
}
}

@media screen and (max-width: $size-md-max) {

#blocIntro p br, #blocIntro h2 br {
    display: none;
}
.blocCoordonnees #bloc50-50_coordonnees .right-side .intro {
    padding-top: 35%;
}
.blocCoordonnees #bloc50-50_coordonnees .left-side .blocMap {
    padding-top: 90%;
}

}

@media screen and (max-width: 1255px) {

.blocBingo {
    margin-top: -495px;
}
}

@media screen and (max-width: 1024px) {

nav.module-menu .menu-desktop .wrapper.left .logo {
    max-width: 300px;
}
nav.module-menu .menu-desktop {
    height: 105px;
}
#bloc50-50 {
    flex-direction: column;
}
#bloc50-50 .left-side {
    margin: 0 auto 85px auto;
}
nav.module-menu .menu-toggle .word {
    color: #fff;
}
nav.module-menu .menu-toggle .bar-top, nav.module-menu .menu-toggle .bar-bottom {
    background-color: #fff;
}
#bloc50-50 .right-side {
    width: 100%;
}
.blocCoordonnees #bloc50-50_coordonnees .right-side .intro {
    padding-top: 57%;
}
.blocCoordonnees #bloc50-50_coordonnees .left-side .blocMap {
    padding-top: 136%;
}

.blocBingo #bloc50-50_bingo .right-side .intro {
    padding-top: 7px;
}
.blocBingo {
    margin-top: -385px;
}
.blocBingo #bloc50-50_bingo h3 {
    font-size: 2rem;
}
.blocBingo #bloc50-50_bingo p {
    font-size: 1.5rem;
    padding-right: 15px;
}
.blocBingo #bloc50-50_bingo .right-side li {
    font-size: 1.5rem;
}
.blocBingo #bloc50-50_bingo .right-side .blocLogo {
    align-items: center;
}
.blocBingo #bloc50-50_horaires .contenu {
    flex-direction: column;
}
.blocBingo #bloc50-50_horaires .contenu .left-side {
    width: 100%;
}
.blocBingo #bloc50-50_horaires .contenu .right-side {
    width: 100%;
}
.blocBingo #bloc50-50_horaires {
    padding: 100px 2% 50px 2%;
}
.blocBingo #bloc50-50_horaires .contenu .right-side .btnSession {
    box-shadow: none;
}
.blocBingo #bloc50-50_horaires .contenu .right-side .images {
    padding-bottom: 50px;
    padding-top: 50px;
}
}

@media screen and (max-width: $size-sm-max) {

nav.module-menu .menu-desktop .wrapper.left .logo {
    max-width: 220px;
}
nav.module-menu .menu-desktop {
    height: 75px;
}
#blocBurst .burst {
    background: url(../images/accueil_section05_burst_hover_m.png) no-repeat center;
    width: 100%;
}
#blocBurst h3.titre {
    padding-top: 80px;
}
.blocCoordonnees #bloc50-50_coordonnees {
    flex-direction: column-reverse;
}
.blocCoordonnees #bloc50-50_coordonnees .left-side .blocMap {
    padding-top: 0;
}
.blocCoordonnees #bloc50-50_coordonnees .left-side {
    width: 100%;
    margin-right: 0;
}
.blocCoordonnees #bloc50-50_coordonnees .right-side {
    width: 100%;
}
.blocCoordonnees #bloc50-50_coordonnees .right-side .intro {
    padding-left: 5%;
}
.blocCoordonnees #bloc50-50_coordonnees .right-side .horaire {
    margin-top: 80px;
    padding-left: 5%;
    padding-bottom: 45px;
}
#blocFormulaire form input.form-control, #blocFormulaire form textarea.form-control {
    width: 100%;
    display: block;
}
#blocFormulaire form label {
    width: 100%;
}


.blocBingo #bloc50-50_bingo .left-side {
    display: none;
}
.blocBingo #bloc50-50_bingo .right-side {
    width: 100%;
}
.blocBingo {
    margin-top: -155px;
}
.blocBingo #bloc50-50_bingo .right-side .btnCalendrier {
    display: block;
}
.blocBingo #bloc50-50_bingo {
    padding: 0 5%;
}
}


@media screen and (max-width: $size-xs-max) {

nav.module-menu .menu-desktop {
    background: #fff;
}
nav.module-menu .menu-toggle .word {
    color: #c31919;
}
nav.module-menu .menu-toggle .bar-top, nav.module-menu .menu-toggle .bar-bottom {
    background-color: #c31919;
}
#bloc50-50 .left-side {
    padding: 30px 0;
    background-size: cover;
}
#bloc50-50 marquee {
    width: 300px;
    height: 300px;
}
#blocBurst .burst {
    padding: 100px 0;
}
.blocCoordonnees #bloc50-50_coordonnees .right-side .horaire {
    padding-right: 2%;
    background-position: top;
}
.blocCoordonnees #bloc50-50_coordonnees .right-side .horaire .horaires .jours {
    padding-right: 5%;
}
.blocCoordonnees #bloc50-50_coordonnees .right-side .horaire .horaires .heuresJour {
    padding-right: 13%;
}
.blocCoordonnees {
    margin-top: -80px;
}
.blocCoordonnees #bloc50-50_coordonnees .right-side .intro {
    padding-top: 0;
    padding-right: 2%;
    padding-left: 2%;
}
.blocBingo #bloc50-50_bingo .right-side .btnCalendrier .border, .blocBingo #bloc50-50_horaires .contenu .right-side .btnSession .border {
    padding: 25px;
}
.blocBingo #bloc50-50_horaires .contenu .right-side .images .img.first {
    margin-right: 0;
    padding-bottom: 25px;
}
.blocBingo #bloc50-50_horaires .contenu .right-side .images {
    flex-direction: column;
}
.blocBingo #bloc50-50_bingo .right-side .description {
    padding: 50px 0 0 0%;
}
.blocBingo #bloc50-50_bingo .right-side .btnCalendrier p br {
    display: none;
}
}

@media screen and (max-width: 375px) {

#blocBurst .burst {
    background: url(../images/accueil_section05_burst_hover_m_small.png) no-repeat center;
}
#blocBurst h3.titre {
    padding-top: 58px;
}
#blocBurst h3 {
    font-size: 2.1rem;
}
}

@media screen and (max-width: 360px) {

.blocCoordonnees #bloc50-50_coordonnees .right-side .horaire .horaires .jours {
    padding-right: 1%;
}
.blocCoordonnees #bloc50-50_coordonnees .right-side .horaire .horaires .heuresJour {
    padding-right: 10%;
}
}
